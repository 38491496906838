import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

Rails.start()
Turbolinks.start()

const application = Application.start()
const controllers = require.context("../website", true, /\.js$/)
application.load(definitionsFromContext(controllers))

// Analytics
document.addEventListener('turbolinks:load', function() {
  ga('send', 'pageview', window.location.pathname)
})